<template>
    <div :class="`c-alert c-alert--${type}`">
        <slot/>
    </div>
</template>

<script>

    export default {
        name: 'InlineAlert',
        props: {
            type: {
                type: String,
                required: true,
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import "../assets/scss/_essentials.scss";

    .c-alert {
        display: block;
        padding: rem(10) rem(20);
        text-align: center;
        z-index: 100;

        &--warning {
            background-color: $brand-warning;
            color: $white;
        }

        &--success {
            background-color: $brand-success;
            color: $white;
        }

        &--error {
            background-color: $brand-danger;
            color: $white;
        }
    }
</style>
