<template>
    <section class="o-account">
        <app-account-nav/>

        <transition-group class="o-account__body" name="fade" tag="div">

            <template v-if="account.status.loading || loading">
                Bezig met ophalen van profiel...
            </template>

            <template v-else>
                <app-intro key="configurations-intro">
                    <h1>Mijn configuraties</h1>

                    <p>Beste gebruiker, welkom op uw persoonlijke portaal waarin al uw configuraties zijn opgeslagen. Wilt u uw configuratie bekijken of wijzigen? Klik dan op één van de onderstaande geconfigureerde (bedrijfspand)namen. Heeft uw bouwplannen of vergaande interesse? Klik dan op de onderstaande button “Neem contact met mij op”. Wij zullen dan zo snel mogelijk contact met u opnemen.</p>

                    <app-button id="ga-contact-request-submit" btn-class="c-btn c-btn--success c-btn--small1" v-confirm="{ ok: contactMe, message: 'Heeft uw bouwplannen en wilt u in contact komen met één van onze adviseurs?', okText: 'Ja, neem contact met mij op', }">
                        Neem contact met mij op
                    </app-button>
                </app-intro>

                <div key="configurations-table" class="table table--bordered" v-if="configurations.length > 0">
                    <div class="table__thead">
                        <div class="table__tr" v-for="config in configurations" :key="'thead-' + config.id">
                            <div class="table__th">Naam</div>
                            <div class="table__th">Stijl</div>
                            <div class="table__th">Prijs</div>
                            <div class="table__th">Datum</div>
                            <div class="table__th"></div>
                        </div>
                    </div>
                    <div class="table__tbody">
                        <div class="table__tr table__tr--hover" @click="loadConfiguration(config.id)" :class="{ 'is-selected': configuration.current.id === config.id && 1 === 2 }" v-for="config in configurations" :key="'tbody-' + config.id">
                            <div class="table__td">{{ config.name }}</div>
                            <div class="table__td">{{ config.style }}</div>
                            <div class="table__td">{{ currency(config.price) }}</div>
                            <div class="table__td">{{ config.created.friendly }}</div>
                            <div class="table__td || c-btn-group c-btn-group--stacked || justify-content-end">
                                <!--<app-button title="Aanvragen" btn-class="c-btn c-btn&#45;&#45;success c-btn&#45;&#45;small" v-show="config.status.id !== 10" :data-id="config.id" v-confirm="{ ok: requestConfiguration, message: 'Weet u zeker dat u deze configuratie wil aanvragen?', okText: 'Aanvragen', }">
                                    <svg width="11" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                                        <path fill="currentColor" d="M288 248v28c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-28c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm-12 72H108c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12v-28c0-6.6-5.4-12-12-12zm108-188.1V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V48C0 21.5 21.5 0 48 0h204.1C264.8 0 277 5.1 286 14.1L369.9 98c9 8.9 14.1 21.2 14.1 33.9zm-128-80V128h76.1L256 51.9zM336 464V176H232c-13.3 0-24-10.7-24-24V48H48v416h288z"></path>
                                    </svg>

                                    Aanvragen
                                </app-button>

                                <app-button btn-class="c-btn c-btn&#45;&#45;success c-btn&#45;&#45;small" v-show="config.status.id === 10">
                                    <svg width="11" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                        <path fill="currentColor" d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
                                    </svg>

                                    Aangevraagd
                                </app-button>-->

                                <!--<app-button btn-class="c-btn c-btn&#45;&#45;tint c-btn&#45;&#45;small" v-show="configuration.current.id !== config.id" v-on:click.native.prevent="loadConfiguration(config.id)">
                                    &lt;!&ndash;<svg width="17" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                        <path fill="currentColor" d="M288 144a110.94 110.94 0 0 0-31.24 5 55.4 55.4 0 0 1 7.24 27 56 56 0 0 1-56 56 55.4 55.4 0 0 1-27-7.24A111.71 111.71 0 1 0 288 144zm284.52 97.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400c-98.65 0-189.09-55-237.93-144C98.91 167 189.34 112 288 112s189.09 55 237.93 144C477.1 345 386.66 400 288 400z"></path>
                                    </svg>&ndash;&gt;
                                    Bekijken
                                </app-button>-->

                                <!--<app-button btn-class="c-btn c-btn&#45;&#45;tint c-btn&#45;&#45;square c-btn&#45;&#45;small" v-show="configuration.current.id === config.id" v-on:click.native.prevent="resetConfiguration(config.name)">
                                   <svg width="17" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                                        <path fill="currentColor" d="M634 471L36 3.51A16 16 0 0 0 13.51 6l-10 12.49A16 16 0 0 0 6 41l598 467.49a16 16 0 0 0 22.49-2.49l10-12.49A16 16 0 0 0 634 471zM296.79 146.47l134.79 105.38C429.36 191.91 380.48 144 320 144a112.26 112.26 0 0 0-23.21 2.47zm46.42 219.07L208.42 260.16C210.65 320.09 259.53 368 320 368a113 113 0 0 0 23.21-2.46zM320 112c98.65 0 189.09 55 237.93 144a285.53 285.53 0 0 1-44 60.2l37.74 29.5a333.7 333.7 0 0 0 52.9-75.11 32.35 32.35 0 0 0 0-29.19C550.29 135.59 442.93 64 320 64c-36.7 0-71.71 7-104.63 18.81l46.41 36.29c18.94-4.3 38.34-7.1 58.22-7.1zm0 288c-98.65 0-189.08-55-237.93-144a285.47 285.47 0 0 1 44.05-60.19l-37.74-29.5a333.6 333.6 0 0 0-52.89 75.1 32.35 32.35 0 0 0 0 29.19C89.72 376.41 197.08 448 320 448c36.7 0 71.71-7.05 104.63-18.81l-46.41-36.28C359.28 397.2 339.89 400 320 400z"></path>
                                    </svg>
                                </app-button>-->

                                <app-button btn-class="c-btn c-btn--primary c-btn--square c-btn--small" :data-id="config.id" v-confirm="{ ok: deleteConfiguration, message: 'Weet u zeker dat u deze configuratie wil verwijderen?', okText: 'Verwijder', }">
                                    <span v-if="config.deleting"><spinner/></span>
                                    <span v-if="!config.deleting">
                                        <svg width="12" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                            <path fill="currentColor" d="M268 416h24a12 12 0 0 0 12-12V188a12 12 0 0 0-12-12h-24a12 12 0 0 0-12 12v216a12 12 0 0 0 12 12zM432 80h-82.41l-34-56.7A48 48 0 0 0 274.41 0H173.59a48 48 0 0 0-41.16 23.3L98.41 80H16A16 16 0 0 0 0 96v16a16 16 0 0 0 16 16h16v336a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128h16a16 16 0 0 0 16-16V96a16 16 0 0 0-16-16zM171.84 50.91A6 6 0 0 1 177 48h94a6 6 0 0 1 5.15 2.91L293.61 80H154.39zM368 464H80V128h288zm-212-48h24a12 12 0 0 0 12-12V188a12 12 0 0 0-12-12h-24a12 12 0 0 0-12 12v216a12 12 0 0 0 12 12z"></path>
                                        </svg>
                                    </span>
                                </app-button>
                            </div>
                        </div>
                    </div>
                </div>

                <template v-else>
                    <inline-alert key="configurations-alert" type="warning">Er zijn nog geen configuraties opgeslagen</inline-alert>
                </template>
            </template>

        </transition-group>
    </section>
</template>

<script>
    import { mapState } from 'vuex';
    import AppButton from '../../components/app/AppButton.vue';
    import AppIntro from '../../components/app/AppIntro.vue';
    import AppAccountNav from '../../components/app/AppAccountNav.vue';
    import InlineAlert from '../../components/InlineAlert.vue';
    import Spinner from '../../components/Spinner.vue';
    import { currency } from '../../helpers/currency';
    import * as ls from '../../helpers/localStorage';
    import router from '../../helpers/router';
    import { accountService, configurationService } from '../../services';

    export default {
        components: {
            AppButton,
            Spinner,
            InlineAlert,
            AppIntro,
            AppAccountNav,
        },
        data () {
            return {
                loading: true,
                configurations: [],
            };
        },
        computed: {
            ...mapState({
                account: (state) => state.account,
                summary: (state) => state.summary,
                configuration: (state) => state.configuration,
            }),
        },
        async mounted () {
            await accountService.user();
            const configurations = await configurationService.get();

            this.loading = false;
            this.configurations = configurations;
        },
        methods: {
            currency (price) {
                return currency(price);
            },
            async contactMe (dialog) {
                const button = dialog.node;
                const { id } = button.dataset;

                await this.$store.dispatch('contact/request', id);
            },
            resetConfiguration (name) {
                // reset configuration
                ls.remove('configuration');

                this.$store.dispatch('configuration/resetCurrent', { name });

                // reset summary
                this.$store.dispatch('summary/resetSummary', ['role', 'size', 'interior', 'exterior', 'installation']);

                // disable tabs
                const nextTabs = this.$store.getters['tabs/getNextTabsFromIndex']('role');
                this.$store.dispatch('tabs/disableTabs', nextTabs);
            },
            loadConfiguration (id) {
                // reset configuration
                ls.remove('configuration');

                // empty the previous summary values
                this.$store.dispatch('summary/resetSummary', ['style', 'role', 'size', 'exterior', 'interior', 'installation']);

                // get configuration
                const { name, configuration } = this.configurations.find((item) => item.id === id);
                const parsedConfiguration = JSON.parse(configuration);

                for (const item in parsedConfiguration)
                {
                    if (Object.prototype.hasOwnProperty.call(parsedConfiguration, item))
                    {
                        const config = parsedConfiguration[item];
                        console.log(config);

                        // single entries
                        if ((typeof config.index !== 'undefined' && !config.length) || config.index === 'style')
                        {
                            // add to summary
                            this.$store.dispatch('summary/addToSummary', {
                                payload: config,
                                index: config.index,
                                type: config.type,
                            });
                        }

                        // check for multiple entries
                        if (typeof config.index === 'undefined' && config.length)
                        {
                            for (const child of config)
                            {
                                // add to summary
                                this.$store.dispatch('summary/addToSummary', {
                                    payload: child,
                                    index: child.index,
                                    type: child.type,
                                });
                            }
                        }

                        // numbers
                        if (typeof config === 'number')
                        {
                            this.$store.dispatch('summary/updateSurfaceArea', config);
                        }
                    }
                }

                // store current
                this.$store.dispatch('configuration/setCurrent', { id, name });

                // enable tabs
                this.$store.dispatch('tabs/enableTabs');

                // redirect to summary
                router.push('/summary');
            },
            async requestConfiguration (dialog) {
                const button = dialog.node;
                const { id } = button.dataset;

                // this.loading = true;

                await this.$store.dispatch('configuration/request', id);
                this.configurations = await configurationService.get();

                // this.loading = false;
                // this.configurations = configurations;
            },
            async deleteConfiguration (dialog) {
                const button = dialog.node;
                const { id } = button.dataset;

                // this.loading = true;

                await this.$store.dispatch('configuration/delete', id);
                this.configurations = await configurationService.get();

                // this.loading = false;
                // this.configurations = configurations;
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/_essentials.scss";

    .c-btn-group {
        .c-btn + .c-btn {
            margin-left: rem(5);
        }
    }

    .table {
        display: grid;
        grid-template-columns: rem(100) 1fr;

        @include media-breakpoint-only(sm) {
            margin: 0 auto;
            max-width: 75%;
        }

        @include media-breakpoint-up(md) {
            grid-template-columns: 1fr;
        }

        &__thead {

        }

        &__tbody {
            background: $gray-light;
        }

        &__tr {
            border-top: rem(1) solid $gray-light;
            display: grid;
            grid-template-columns: 1fr;

            @include media-breakpoint-up(md) {
                grid-template-columns: repeat(5, 1fr);
            }

            @include media-breakpoint-up(lg) {
                grid-template-columns: 2fr repeat(3, 1fr) rem(225);
            }

            &--hover {
                cursor: pointer;

                .table__td {
                    transition: $transition-base;
                }

                @include hover-focus {
                    .table__td {
                        background-color: $gray-lightest;
                    }
                }
            }

            &.is-selected {
                outline: rem(1) solid $brand-success;

                @include hover-focus {
                    cursor: auto;

                    .table__td {
                        background-color: $white;
                    }
                }
            }

            .table__thead & {
                @include media-breakpoint-up(md) {
                    display: none;

                    &:first-child {
                        display: grid;
                    }
                }
            }
        }

        &__td,
        &__th {
            background-color: $white;
            padding: rem(10) rem(20);

            @include media-breakpoint-down(xsl) {
                min-height: rem(50);
            }

            @include media-breakpoint-only(sm) {
                min-height: rem(55);
            }
        }

        &__th {
            border-left: none;
            border-right: none;
        }

        &__td {

        }

        &__th {
            color: $text-color;
            font-weight: $font-weight-black;

            @include media-breakpoint-up(sm) {
                font-size: rem(20);
                padding-top: rem(10);
                padding-bottom: rem(10);
            }

            @include media-breakpoint-up(xl) {
                padding-top: rem(15);
                padding-bottom: rem(15);
            }
        }
    }

    .form {
        grid-gap: rem(40);
        max-width: 85%;
    }

    .form-submit-btn {
        justify-self: start;
    }
</style>
