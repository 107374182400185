import { render, staticRenderFns } from "./AppAccountNav.vue?vue&type=template&id=3d63817a&scoped=true"
import script from "./AppAccountNav.vue?vue&type=script&lang=js"
export * from "./AppAccountNav.vue?vue&type=script&lang=js"
import style0 from "./AppAccountNav.vue?vue&type=style&index=0&id=3d63817a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d63817a",
  null
  
)

export default component.exports